import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, Polygon, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import PropTypes from 'prop-types';

// Function to create a circle icon for marks
const createCircleIcon = () => {
  return L.divIcon({
    html: `<div style="width: 12px; height: 12px; background-color: blue; border-radius: 50%;"></div>`,
    className: 'circle-icon',
    iconSize: [12, 12],
    iconAnchor: [6, 6],
  });
};

// Function to create a rotating triangle icon
const createTriangleIcon = (cog, color, opacity = 1) => {
  const svg = `
    <svg width="12" height="40" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <polygon points="10,-10 19,19 1,19" style="fill:${color};stroke:black;stroke-width:1;opacity:${opacity}" />
    </svg>`;
  return L.divIcon({
    html: `<div style="transform: rotate(${cog}deg);">${svg}</div>`,
    className: 'triangle-icon',
    iconSize: [20, 40], // Adjust size as needed
    iconAnchor: [10, 20], // Center the icon
  });
};

// Function to calculate time since last update
const timeSinceUpdate = (timestamp) => {
  const now = new Date();
  const updateTime = new Date(timestamp);
  if (isNaN(updateTime.getTime())) {
    return "Invalid timestamp";
  }
  const diffMs = now - updateTime;
  const diffMins = Math.floor(diffMs / 60000);
  return diffMins;
};

// Function to calculate the midpoint between two coordinates
const calculateMidpoint = (point1, point2) => {
  const lat = (point1.lat + point2.lat) / 2;
  const lng = (point1.lng + point2.lng) / 2;
  return { lat, lng };
};

// Function to generate polylines with dashed lines for gaps over 5 minutes
const generatePolylines = (boatData, boatColors) => {
  const gapThreshold = 5 * 60 * 1000; // 5 minutes in milliseconds
  const polylines = [];

  boatData.forEach(boat => {
    const positions = boat.data.map(point => ({
      lat: point.lat,
      lng: point.lng,
      timestamp: new Date(point.timestamp).getTime()
    }));

    let lastTimestamp = positions[0].timestamp;
    let currentLine = [positions[0]];

    for (let i = 1; i < positions.length; i++) {
      const currentPosition = positions[i];
      const currentTimestamp = currentPosition.timestamp;

      if (currentTimestamp - lastTimestamp > gapThreshold) {
        polylines.push({ positions: currentLine, color: boatColors[boat.mmsi], dashArray: '0' });
        polylines.push({ positions: [currentLine[currentLine.length - 1], currentPosition], color: boatColors[boat.mmsi], dashArray: '4' });
        currentLine = [currentPosition];
      } else {
        currentLine.push(currentPosition);
      }

      lastTimestamp = currentTimestamp;
    }

    if (currentLine.length > 1) {
      polylines.push({ positions: currentLine, color: boatColors[boat.mmsi], dashArray: '0' });
    }
  });

  return polylines;
};

const MapComponent = ({ boatData, boatColors, boatInfo, racecourse }) => {
  const startMid = racecourse && calculateMidpoint(racecourse.startLine.point1, racecourse.startLine.point2);
  const finishMid = racecourse && calculateMidpoint(racecourse.finishLine.point1, racecourse.finishLine.point2);

  const coursePositions = racecourse ? [
    [startMid.lat, startMid.lng],
    ...racecourse.marks.map(mark => [mark.position.lat, mark.position.lng]),
    [finishMid.lat, finishMid.lng]
  ] : [];

  const polylines = generatePolylines(boatData, boatColors);

  return (
    <MapContainer center={[50.5, -1.5]} zoom={10} className="leaflet-container" zoomControl={false}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {polylines.map((polyline, index) => (
        <Polyline
          key={index}
          positions={polyline.positions.map(pos => [pos.lat, pos.lng])}
          color={polyline.color}
          dashArray={polyline.dashArray}
        />
      ))}
      {boatData.map(boat => {
        const latestPosition = boat.data[boat.data.length - 1];
        const minutesSinceUpdate = timeSinceUpdate(latestPosition.timestamp);
        const isStale = minutesSinceUpdate > 10;
        const opacity = isStale ? 0.5 : 1;
        const triangleIcon = createTriangleIcon(latestPosition.cog, boatColors[boat.mmsi], opacity);

        return (
          <Marker key={boat.mmsi} position={[latestPosition.lat, latestPosition.lng]} icon={triangleIcon}>
            <Popup>
              <div>
                <strong>{boatInfo[boat.mmsi]?.name || boat.mmsi}</strong><br />
                <strong>Skipper:</strong> {boatInfo[boat.mmsi]?.skipper || 'Unknown'}<br />
                <strong>COG:</strong> {latestPosition.cog}<br />
                <strong>SOG:</strong> {latestPosition.sog}<br />
                <strong>Last Update:</strong> {minutesSinceUpdate} mins ago
              </div>
            </Popup>
          </Marker>
        );
      })}
      {racecourse && racecourse.marks && racecourse.marks.map((mark, index) => (
        !mark.hidden && (
          <Marker key={`mark-${index}`} position={[mark.position.lat, mark.position.lng]} icon={createCircleIcon()}>
            <Popup>{mark.name}</Popup>
          </Marker>
        )
      ))}
      {racecourse && racecourse.startLine && (
        <Polyline positions={[
          [racecourse.startLine.point1.lat, racecourse.startLine.point1.lng],
          [racecourse.startLine.point2.lat, racecourse.startLine.point2.lng]
        ]} color="green" />
      )}
      {racecourse && racecourse.finishLine && (
        <Polyline positions={[
          [racecourse.finishLine.point1.lat, racecourse.finishLine.point1.lng],
          [racecourse.finishLine.point2.lat, racecourse.finishLine.point2.lng]
        ]} color="red" />
      )}
      {startMid && finishMid && (
        <Polyline positions={coursePositions} color="blue" />
      )}
      {racecourse && racecourse.exclusionZones && racecourse.exclusionZones.map((zone, index) => (
        <Polygon key={`zone-${index}`} positions={zone.points.map(point => [point.lat, point.lng])} color="red" fillOpacity={0.3}>
          <Tooltip permanent direction="center" className="fixed-tooltip">
            <span>{zone.name}</span>
          </Tooltip>
        </Polygon>
      ))}
    </MapContainer>
  );
};

MapComponent.propTypes = {
  boatData: PropTypes.array.isRequired,
  boatColors: PropTypes.object.isRequired,
  boatInfo: PropTypes.object.isRequired,
  racecourse: PropTypes.object,
};

export default MapComponent;
