import React, { useState, useEffect, useCallback, useRef } from 'react';
import Papa from 'papaparse';
import MapComponent from './MapComponent';
import FleetMenu from './FleetMenu';
import { saveAs } from 'file-saver';
import './App.css';

const App = () => {
  const [boatData, setBoatData] = useState([]);
  const [selectedMMSI, setSelectedMMSI] = useState([]);
  const [boatInfo, setBoatInfo] = useState({});
  const [racecourse, setRacecourse] = useState(null);
  const [showRacecourse, setShowRacecourse] = useState(true);  // Default to showing the racecourse
  const boatColorsRef = useRef({});

  useEffect(() => {
    const storedSelectedMMSI = localStorage.getItem('selectedMMSI');
    if (storedSelectedMMSI) {
      setSelectedMMSI(JSON.parse(storedSelectedMMSI));
    }
  }, []);

  const generateColor = (mmsi) => {
    let hash = 0;
    for (let i = 0; i < mmsi.length; i++) {
      hash = mmsi.charCodeAt(i) + ((hash << 5) - hash);
    }
    const h = (hash % 360 + 360) % 360;
    return `hsl(${h}, 100%, 50%)`;
  };

  const assignColors = useCallback((boatList) => {
    boatList.forEach((boat) => {
      if (!boatColorsRef.current[boat.mmsi]) {
        boatColorsRef.current[boat.mmsi] = generateColor(boat.mmsi);
      }
    });
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const boatDataUrl = `https://storage.googleapis.com/racetrackdata/test1output.csv?cache_bust=${new Date().getTime()}`;
      const mmsiListUrl = `https://storage.googleapis.com/racetrackdata/mmsi_list.csv?cache_bust=${new Date().getTime()}`;
      const racecourseConfigUrl = `https://storage.googleapis.com/racetrackdata/racecourse_config.json?cache_bust=${new Date().getTime()}`;

      const [boatDataResponse, mmsiListResponse, racecourseConfigResponse] = await Promise.all([
        fetch(boatDataUrl),
        fetch(mmsiListUrl),
        fetch(racecourseConfigUrl).catch(error => {
          console.error('Error fetching racecourse config:', error);
          return null;
        }),
      ]);

      const [boatDataText, mmsiListText, racecourseConfigText] = await Promise.all([
        boatDataResponse.text(),
        mmsiListResponse.text(),
        racecourseConfigResponse ? racecourseConfigResponse.text() : Promise.resolve(null),
      ]);

      const parsedBoatData = Papa.parse(boatDataText, { header: true });
      const parsedMmsiList = Papa.parse(mmsiListText, { header: true, skipEmptyLines: true });
      const parsedRacecourseConfig = racecourseConfigText ? JSON.parse(racecourseConfigText) : null;

      const boats = {};
      const boatInfo = {};

      parsedMmsiList.data.forEach((boat) => {
        const mmsi = boat.MMSI.trim();
        const boatClasses = boat.Class.split(';').map(cls => cls.trim());
        boatInfo[mmsi] = { name: boat.Name, classes: boatClasses, skipper: boat.Skipper };
      });

      parsedBoatData.data.forEach((row) => {
        Object.keys(row).forEach((key) => {
          const lastUnderscoreIndex = key.lastIndexOf('_');
          const mmsi = key.substring(0, lastUnderscoreIndex).trim();
          const field = key.substring(lastUnderscoreIndex + 1);
          if (!boats[mmsi]) boats[mmsi] = { mmsi, data: [] };
          if (!boats[mmsi][field]) boats[mmsi][field] = [];
          if (row[key]) boats[mmsi][field].push(row[key]);
        });
      });

      const boatList = Object.values(boats).map((boat) => {
        if (!boat.LAT || !boat.LONG) return null;
        return {
          mmsi: boat.mmsi,
          data: boat.LAT.map((lat, index) => {
            const lng = boat.LONG[index];
            const cog = boat.COG ? boat.COG[index] : null;
            const sog = boat.SOG ? boat.SOG[index] : null;
            const timestamp = boat.LASTUPDATE ? boat.LASTUPDATE[index] : null;

            if (lat && lng) {
              const parsedLat = parseFloat(lat);
              const parsedLng = parseFloat(lng);
              if (isNaN(parsedLat) || isNaN(parsedLng)) return null;
              return {
                lat: parsedLat,
                lng: parsedLng,
                cog: cog !== null ? parseFloat(cog) : null,
                sog: sog !== null ? parseFloat(sog) : null,
                timestamp: timestamp,
              };
            }
            return null;
          }).filter((point) => point !== null),
        };
      }).filter((boat) => boat.data.length > 0);

      assignColors(boatList);

      setBoatData(boatList);
      setBoatInfo(boatInfo);
      setRacecourse(parsedRacecourseConfig);

      if (!localStorage.getItem('selectedMMSI')) {
        setSelectedMMSI(boatList.map((boat) => boat.mmsi));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [assignColors]);

  const downloadData = () => {
    const latestPositions = boatData.map(boat => {
      const latestPosition = boat.data[boat.data.length - 1];
      return [
        boatInfo[boat.mmsi]?.name || 'Unknown',
        boat.mmsi,
        latestPosition.lat,
        latestPosition.lng,
        new Date(latestPosition.timestamp).toLocaleString()
      ].join(',');
    }).join('\n');

    const csvContent = `data:text/csv;charset=utf-8,${latestPositions}`;

    const blob = new Blob([decodeURIComponent(encodeURI(csvContent))], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `positions_${new Date().toLocaleDateString('en-US')}.csv`);
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);
  }, [fetchData]);

  useEffect(() => {
    if (selectedMMSI.length > 0) {
      localStorage.setItem('selectedMMSI', JSON.stringify(selectedMMSI));
    }
  }, [selectedMMSI]);

  const handleMMSIChange = (selected) => {
    setSelectedMMSI(selected);
  };

  return (
    <div className="App">
      <FleetMenu boats={boatData} selectedMMSI={selectedMMSI} onMMSIChange={handleMMSIChange} boatColors={boatColorsRef.current} boatInfo={boatInfo} />
      <MapComponent boatData={boatData.filter((boat) => selectedMMSI.includes(boat.mmsi))} boatColors={boatColorsRef.current} boatInfo={boatInfo} racecourse={showRacecourse ? racecourse : null} />
      <div className="title-overlay">
        <h1>RaceTrack ALPHA</h1>
        <p>Terrestrial AIS Data - Poor Coverage</p>
        <button className="download-button button" onClick={downloadData}>Download Latest</button>
        <button className="toggle-racecourse-button button" onClick={() => setShowRacecourse(!showRacecourse)}>
          {showRacecourse ? 'Hide' : 'Show'} Racecourse
        </button>
      </div>
    </div>
  );
};

export default App;
