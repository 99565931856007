import React, { useState } from 'react';
import Select from 'react-select';
import './FleetMenu.css';

const FleetMenu = ({ boats, selectedMMSI, onMMSIChange, boatColors, boatInfo }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const classes = {};
  boats.forEach((boat) => {
    const boatClasses = boatInfo[boat.mmsi]?.classes || ['Unknown'];
    boatClasses.forEach((boatClass) => {
      if (!classes[boatClass]) classes[boatClass] = [];
      classes[boatClass].push(boat);
    });
  });

  const options = Object.keys(classes).map((boatClass) => {
    const classOptions = classes[boatClass].map((boat) => ({
      value: boat.mmsi,
      label: (
        <div>
          <span style={{ backgroundColor: boatColors[boat.mmsi], width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
          {boatInfo[boat.mmsi]?.name || boat.mmsi} (SOG: {boat.data[boat.data.length - 1]?.sog || 0})
        </div>
      ),
    }));

    return {
      label: `Class ${boatClass}`,
      options: [
        { value: `all_${boatClass}`, label: `All Class ${boatClass}` },
        ...classOptions,
      ],
    };
  });

  const handleChange = (selectedOptions) => {
    const selectedSet = new Set();
    selectedOptions.forEach((option) => {
      if (option.value.startsWith('all_')) {
        const boatClass = option.value.split('_')[1];
        const classMMSIs = classes[boatClass].map((boat) => boat.mmsi);
        classMMSIs.forEach((mmsi) => selectedSet.add(mmsi));
      } else {
        selectedSet.add(option.value);
      }
    });

    const selected = Array.from(selectedSet);
    onMMSIChange(selected);
  };

  const selectedOptions = selectedMMSI.map((mmsi) => ({
    value: mmsi,
    label: (
      <div>
        <span style={{ backgroundColor: boatColors[mmsi], width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
        {boatInfo[mmsi]?.name || mmsi} (SOG: {boats.find((boat) => boat.mmsi === mmsi)?.data.slice(-1)[0]?.sog || 0})
      </div>
    ),
  }));

  return (
    <div className="fleet-menu">
      <div className="fleet-menu-header">
        <h2>Fleet</h2>
        <button onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? 'Show' : 'Hide'}
        </button>
      </div>
      {!isCollapsed && (
        <>
          <p>Select boats to display:</p>
          <div className="fleet-menu-select-container">
            <Select
              classNamePrefix="react-select"
              isMulti
              options={options}
              value={selectedOptions}
              onChange={handleChange}
              placeholder="Select boats to display"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FleetMenu;
